import SummaryApi from "../common";
import { toast } from 'react-toastify';

const addToCart = async (e, id) => {
    e?.stopPropagation();
    e?.preventDefault();

    const token = localStorage.getItem("token");

    if (!token) {
        // Gérer le cas où le token est absent
        toast.error("connectez-vous svp!");
        console.error("Token absent. Veuillez vous connecter.");
        return null;
    }

    const requestOptions = {
        method: SummaryApi.addToCartProduct.method,
        credentials: 'include',
        headers: {
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ productId: id })
    };

    try {
        const response = await fetch(SummaryApi.addToCartProduct.url, requestOptions);
        const responseData = await response.json();

        if (responseData.success) {
            toast.success(responseData.message);
        } else {
            toast.error(responseData.message);
        }

        return responseData;
    } catch (error) {
        // Gérer les erreurs de la requête fetch
        console.error("Erreur lors de l'ajout au panier :", error);
        return null;
    }
};

export default addToCart;
