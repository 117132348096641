import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FaRegCircleUser } from "react-icons/fa6";
import { Link, Outlet, useNavigate } from 'react-router-dom';
import ROLE from '../common/role';
import { FaUsers } from "react-icons/fa";
import { BsCart4 } from "react-icons/bs";


const AdminPanel = () => {
    const user = useSelector(state => state?.user?.user)
    const navigate = useNavigate()


    useEffect(() => {
        if (user?.role !== ROLE.ADMIN) {
            navigate("/")
        }
    }, [user])

    return (
        <div className='min-h-[calc(100vh-120px)] md:flex-row w-full'>

            <aside className='flex md:flex-row bg-slate-600 min-h-full  w-full   customShadow'>
                <div className='h-32  flex justify-between items-center flex-col'>
                    <div className='text-5xl cursor-pointer relative flex justify-center gap-4'>
                        {
                            user?.profilePic ? (
                                <img src={user?.profilePic} className='w-10 h-10 rounded-full' alt={user?.name} />
                            ) : (
                                <FaRegCircleUser />
                            )
                        }
                    </div>
                    <p className='capitalize text-lg font-semibold'>{user?.name}</p>
                    <p className='text-sm'>{user?.role}</p>
                </div>

                {/***navigation */}
                <div>
                    <nav className='flex justify-between items-center p-4 gap-3 w-full'>
                        <Link to={"all-users"} className='px-2 py-1 hover:bg-orange-500 hover:text-white flex border border-spacing-1 text-white border-white'> <FaUsers className='m-2' />Clients</Link>
                        <Link to={"all-products"} className='px-2 py-1 hover:bg-orange-500 hover:text-white  flex border border-spacing-1 text-white border-white'><BsCart4 className='m-2' />Produits</Link>
                    </nav>
                </div>
            </aside>

            <main className='w-full h-full p-2'>
                <Outlet />
            </main>
        </div>
    )
}

export default AdminPanel