import React, { useState } from 'react';
import { BsWhatsapp } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa6";
import { PiPhoneCallFill } from "react-icons/pi";
import { Link } from 'react-router-dom';
import { MdOutlineMailOutline } from "react-icons/md";



const Footer = () => {
  const [currentYear] = useState(new Date().getFullYear());

  return (
    <footer className='bg-slate-900 w-auto h-auto flex justify-center items-center flex-col mt-20'>
      <div className='mt-5'>
        <span className='text-white text-3xl'>T&D</span>
        <span className='text-orange-600 '> Glam's</span>
      </div>

      <div className='flex justify-center items-center gap-10 mt-5 mb-5'>
        <Link to={"https://api.whatsapp.com/send?phone=237691461614"} target='_blank' rel="noreferrer"
        >
          <BsWhatsapp
            className='w-5 h-5 text-green-600 transform hover:scale-110 transition duration-300 cursor-pointer'
          />
        </Link>
        <Link to={'https://www.facebook.com/people/M%C3%A9lina-Diwa/61556920802762/?mibextid=LQQJ4d'} target='_blank' rel="noreferrer">
          <FaFacebook
            className='w-5 h-5 text-white transform hover:scale-110 transition duration-300 cursor-pointer'

          />
        </Link>

        <Link to={'mailto:melinadiwa@icloud.com'} target='_blank' rel="noreferrer"
        >
          <MdOutlineMailOutline
            className='w-5 h-5 text-red-600 transform hover:scale-110 transition duration-300 cursor-pointer'
          />
        </Link>

        <Link to={'tel:+237691461614'}>
          <PiPhoneCallFill
            className='w-5 h-5 text-blue-700 transform hover:scale-110 transition duration-300 cursor-pointer' />
        </Link>
      </div>

      <div className='text-white mb-5'>
        Copyright &copy;
        <span className='text-blue-700'>T&D</span>
        <span className='text-orange-600 text-sm'>Glam's</span> | Tous droits réservés. {currentYear}
      </div>
    </footer>
  )
}

export default Footer