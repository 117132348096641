const productCategory = [
    { id: 1, label: "Vêtements", value: "Vêtement" },
    { id: 2, label: "Chaussure", value: "Chaussure" },
    { id: 3, label: "Téléphone", value: "Téléphone" },
    { id: 4, label: "T-shirt", value: "T-shirt" },
    { id: 5, label: "Pantalons", value: "Pantalons" },
    { id: 6, label: "Electronique", value: "Electronique" },
    { id: 7, label: "Lingerie", value: "Lingerie" },
    { id: 8, label: "Robe", value: "Robe" },
    { id: 9, label: "Jupe", value: "Jupe" },
    { id: 10, label: "Cosmétique", value: "Cosmétique" },
    { id: 11, label: "Ordinateur", value: "Ordinateur" },
    { id: 12, label: "Autres", value: "Autres" },

];

export default productCategory