import React from 'react'
import CategoryList from '../components/CategoryList'
import BannerProduct from '../components/BannerProduct'
import HorizontalCardProduct from '../components/HorizontalCardProduct'
import VerticalCardProduct from '../components/VerticalCardProduct'

const Home = () => {
  return (
    <div>
      <CategoryList />
      <BannerProduct />

      <HorizontalCardProduct category={"Vêtement"} heading={"Vêtement"} />
      <HorizontalCardProduct category={"Chaussure"} heading={"Chaussure"} />
      <VerticalCardProduct category={"Téléphone"} heading={"Téléphone"} />
      <VerticalCardProduct category={"T-shirt"} heading={"T-shirt"} />
      <VerticalCardProduct category={"Pantalon"} heading={"Pantalon"} />
      <VerticalCardProduct category={"Electronique"} heading={"Electronique"} />
      <VerticalCardProduct category={"Lingerie"} heading={"Lingerie"} />
      <VerticalCardProduct category={"Robe"} heading={"Robe"} />
      <VerticalCardProduct category={"Jupe"} heading={"Jupe"} />
      <VerticalCardProduct category={"Cosmétique"} heading={"Cosmétique"} />
      <VerticalCardProduct category={"Ordinateur"} heading={"Ordinateur"} />

    </div>
  )
}

export default Home