import React, { useContext, useEffect, useRef, useState } from 'react'
import fetchCategoryWiseProduct from '../helpers/fetchCategoryWiseProduct'
import displayINRCurrency from '../helpers/displayCurrency'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import addToCart from '../helpers/addToCart'
import Context from '../context'
import scrollTop from '../helpers/scrollTop'

const CategroyWiseProductDisplay = ({ category, heading }) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const loadingList = new Array(13).fill(null)

    const { fetchUserAddToCart } = useContext(Context)

    const handleAddToCart = async (e, id) => {
        await addToCart(e, id)
        fetchUserAddToCart()
    }




    const fetchData = async () => {
        setLoading(true)
        const categoryProduct = await fetchCategoryWiseProduct(category)
        setLoading(false)

        console.log("horizontal data", categoryProduct.data)
        setData(categoryProduct?.data)
    }

    useEffect(() => {
        fetchData()
    }, [])




    return (
        <div className='container  relative md:left-28 left-auto mt-10'>
            <h2 className='text-2xl font-semibold py-4'>{heading}</h2>

            <div className='grid grid-cols-2 md:grid-cols-[repeat(auto-fit,minmax(300px,320px))] gap-6 md:gap-6 overflow-x-scroll scrollbar-none transition-all'>
                {
                    loading ? (
                        loadingList.map((product, index) => {
                            return (
                                <div key={index} className='w-full min-w-[140px] md:min-w-[320px] max-w-[140px] md:max-w-[320px] bg-white rounded-sm shadow'>
                                    <div className='bg-slate-200 h-48 p-4 min-w-[140px] md:min-w-[145px] flex justify-center items-center animate-pulse'>
                                    </div>
                                    <div className='p-4 grid gap-1'>
                                        <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black p-1 py-2 animate-pulse rounded-full bg-slate-200'></h2>
                                        <p className='capitalize text-slate-500 p-1 animate-pulse rounded-full bg-slate-200 py-2'></p>
                                        <div className='flex gap-3'>
                                            <p className='text-red-600 font-medium p-1 animate-pulse rounded-full bg-slate-200 w-full py-2'></p>
                                            <p className='text-slate-500 line-through p-1 animate-pulse rounded-full bg-slate-200 w-full py-2'></p>
                                        </div>
                                        <button className='text-sm text-white w-full rounded-full bg-slate-200 py-2 animate-pulse'></button>
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        data.map((product, index) => {
                            return (
                                <Link key={index} to={"/product/" + product?._id} className='w-full min-w-[140px] md:min-w-[320px] max-w-[140px] md:max-w-[320px] bg-white rounded-sm shadow' onClick={scrollTop}>
                                    <div className='bg-slate-200 h-48 p-4 min-w-[140px] md:min-w-[145px] flex justify-center items-center'>
                                        <img src={product.productImage[0]} className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply' />
                                    </div>
                                    <div className='p-4 flex justify-center items-center flex-col'>
                                        <h2 className='font-medium text-base md:text-[2px] text-ellipsis line-clamp-1 text-black'>{product?.productName}</h2>
                                        <p className='capitalize text-slate-500'>{product?.category}</p>
                                        <div className='flex gap-3'>
                                            <p className='text-red-600 font-medium'>{displayINRCurrency(product?.sellingPrice)}</p>
                                        </div>
                                        <div className='flex gap-3'>
                                            <p className='text-slate-500 line-through'>{displayINRCurrency(product?.price)}</p>

                                        </div>
                                        <button
                                            className='text-sm md:text-[10px] w-full md:w-[50] bg-blue-600 hover:bg-red-700 text-white  min-w-[100px] max-w-[180px]  sm:py-3 md:py-2 rounded-full' onClick={(e) => handleAddToCart(e, product?._id)}>
                                            Ajouter
                                        </button>
                                    </div>
                                </Link>
                            )
                        })
                    )
                }
            </div>
        </div>
    )



}

export default CategroyWiseProductDisplay