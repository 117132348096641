import React, { useState } from 'react';
import { MdModeEditOutline } from "react-icons/md";
import AdminEditProduct from './AdminEditProduct';
import displayINRCurrency from '../helpers/displayCurrency';

const AdminProductCard = ({ data, fetchdata }) => {
  const [editProduct, setEditProduct] = useState(false);

  return (
    <div className='bg-white p-4 rounded shadow-md'> {/* Added shadow-md for subtle depth */}
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4'> {/* Responsive grid layout */}
        <div className='w-full md:w-40'>
          <div className='w-full h-32 flex justify-center items-center'>
            <img src={data?.productImage[0]} className='mx-auto object-fill h-full' />
          </div>
          <h1 className='text-ellipsis line-clamp-2'>{data.productName}</h1>
        </div>
        <div className='hidden md:flex flex-col justify-center'> {/* Only visible on medium screens and larger */}
          <p className='font-semibold'>
            {displayINRCurrency(data.sellingPrice)}
          </p>
          <div className='w-fit ml-auto p-2 bg-green-100 hover:bg-green-600 rounded-full hover:text-white cursor-pointer' onClick={() => setEditProduct(true)}>
            <MdModeEditOutline />
          </div>
        </div>
        <div className='flex md:hidden justify-end'> {/* Only visible on small screens */}
          <p className='font-semibold'>
            {displayINRCurrency(data.sellingPrice)}
          </p>
          <div className='w-fit ml-auto p-2 bg-green-100 hover:bg-green-600 rounded-full hover:text-white cursor-pointer' onClick={() => setEditProduct(true)}>
            <MdModeEditOutline />
          </div>
        </div>
      </div>

      {editProduct && (
        <AdminEditProduct productData={data} onClose={() => setEditProduct(false)} fetchdata={fetchdata} />
      )}
    </div>
  );
};

export default AdminProductCard;
