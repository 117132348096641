import React, { useContext, useState } from 'react'
import { GrSearch } from "react-icons/gr";
import { FaRegCircleUser } from "react-icons/fa6";
import { TiShoppingCart } from "react-icons/ti";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SummaryApi from '../common';
import { toast } from 'react-toastify'
import { setUserDetails } from '../store/userSlice';
import ROLE from '../common/role';
import Context from '../context';
import { FaLock } from "react-icons/fa6";
import { IoLockOpenSharp } from "react-icons/io5";



const Header = () => {
  const user = useSelector(state => state?.user?.user)
  const dispatch = useDispatch()
  const [menuDisplay, setMenuDisplay] = useState(false)
  const context = useContext(Context)
  const navigate = useNavigate()
  const searchInput = useLocation()
  const URLSearch = new URLSearchParams(searchInput?.search)
  const searchQuery = URLSearch.getAll("q")
  const [search, setSearch] = useState(searchQuery)

  const handleLogout = async () => {
    const fetchData = await fetch(SummaryApi.logout_user.url, {
      method: SummaryApi.logout_user.method,
      credentials: 'include'
    })

    const data = await fetchData.json()

    if (data.success) {
      toast.success(data.message)
      localStorage.removeItem("token");
      dispatch(setUserDetails(null))
      navigate("/")
    }

    if (data.error) {
      toast.error(data.message)
    }

  }

  const handleSearch = (e) => {
    const { value } = e.target
    setSearch(value)

    if (value) {
      navigate(`/search?q=${value}`)
    } else {
      navigate("/search")
    }
  }
  return (
    <header className='h-16 shadow-md bg-white fixed w-full z-40'>
      <div className=' h-full container mx-auto flex items-center px-4 justify-between'>
        <div className='gap-1'>
          <Link to={"/"}>
            <span className='text-blue-700 text-3xl left-0'>T&D</span>
            <span className='text-orange-600 md:mr-1'>Glam's</span>
          </Link>
        </div>

        <div className="gap-1 flex justify-between items-center w-full max-w-sm rounded-full border focus-within:shadow pl-2">
          <input
            type="text"
            placeholder="recherche des produits..."
            className="w-full outline-none bg-transparent" // Transparent background for smaller screens
            onChange={handleSearch}
            value={search}
          />
          <div className="text-lg min-w-[20px] h-8 bg-blue-600 flex items-center justify-center rounded-r-full text-white">
            <GrSearch />
          </div>
        </div>



        <div className='flex items-center gap-7'>

          <div className='relative flex justify-center'>

            {
              user?._id && (
                <div className='text-3xl cursor-pointer relative flex justify-center' onClick={() => setMenuDisplay(preve => !preve)}>
                  {
                    user?.profilePic ? (
                      <img src={user?.profilePic} className='w-10 h-10 rounded-full' alt={user?.name} />
                    ) : (
                      <FaRegCircleUser />
                    )
                  }
                </div>
              )
            }


            {
              menuDisplay && (
                <div className='absolute bg-white bottom-0 top-11 h-fit p-2 shadow-lg rounded' >
                  <nav>
                    {
                      user?.role === ROLE.ADMIN && (
                        <Link to={"/admin-panel/all-products"} className='whitespace-nowrap hidden md:block hover:bg-slate-100 p-2' onClick={() => setMenuDisplay(preve => !preve)}>Admin Panel</Link>
                      )
                    }

                  </nav>
                </div>
              )
            }
            {
              menuDisplay && (
                <div
                  className={`absolute bg-white bottom-0 top-11 h-fit p-2 shadow-lg rounded ${menuDisplay ? "" : "hidden" // Toggle visibility based on menuDisplay
                    }`}
                >
                  <nav>
                    {user?.role === ROLE.ADMIN && (
                      <Link
                        to={"/admin-panel/all-products"}
                        className={`whitespace-nowrap ${menuDisplay ? "block" : "hidden md:block" // Responsive toggle
                          } hover:bg-slate-100 p-2`}
                        onClick={() => setMenuDisplay(prev => !prev)}
                      >
                        Admin Panel
                      </Link>
                    )}
                  </nav>
                </div>
              )
            }


          </div>

          {
            user?._id && (
              <Link to={"/cart"} className='text-2xl relative'>
                <span><TiShoppingCart /></span>

                <div className='bg-orange-600 text-white w-5 h-5 rounded-full p-1 flex items-center justify-center absolute -top-2 -right-3'>
                  <p className='text-sm'>{context?.cartProductCount}</p>
                </div>
              </Link>
            )
          }



          <div>
            {
              user?._id ? (
                <button onClick={handleLogout}
                  className='px-3 py-1 w-10 h-10 rounded-full text-white bg-blue-600 hover:bg-orange-500'>
                  <FaLock />
                </button>
              )
                : (
                  <Link to={"/login"}
                  >
                    <IoLockOpenSharp className='px-3 py-1 w-10 h-10 rounded-full text-white bg-blue-600 hover:bg-orange-500' />
                  </Link>
                )
            }

          </div>

        </div>

      </div>
    </header>
  )
}

export default Header